'use strict';

$(function(){
  $(".js__contents--ellipsis").each(function(){
    if ($(this).text().length > 50) {
      $(this).text($(this).text().substr(0, 49)); 
      $(this).append('… ');
    }
  });

  $('a[href^="#"]').click(function(){
    var speed = 500;
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    $("html, body").animate({scrollTop:position}, speed, "swing");
    return false;
  });

  let state = false;
  let scrollpos;
  $('.menu-open').on('click', function(){
    $(this).toggleClass('active');
    $(".gnav-sp").toggleClass('panelactive');
    if(state === false) {
      scrollpos = $(window).scrollTop();
      $('body').addClass('fixed').css({'top': -scrollpos});
      state = true;
    } else {
      $('body').removeClass('fixed').css({'top': 0});
      $(window).scrollTop( scrollpos );
      state = false;
    }
  });

  $(".gnav-sp a").click(function () {
      $(".menu-open").removeClass('active');
      $(".gnav-sp").removeClass('panelactive');
  });

});

$(window).on('load', function() {
	const url = $(location).attr('href'),
	headerHeight = $('header').outerHeight() + 30;

	if(url.indexOf("#") != -1){
		const anchor = url.split("#"),
		target = $('#' + anchor[anchor.length - 1]),
		position = Math.floor(target.offset().top) - headerHeight;
		$("html, body").animate({scrollTop:position}, 500);
	}
});
